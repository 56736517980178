import { useTranslation } from "@i18n";
import stl from "./SellTradeHome.module.css";
import { CDN_LINK } from "@globalConfig";
import { useLang } from "@/common/Contexts/LanguageContext";
import ProgressiveImage from "@componentsShared/ProgressiveImage";
import { URL_SELL_TRADE_LANDING } from "@/pages/module-sell-trade/SellTradeContext";
import useHelpers from "@hooks/useHelpers";
import { trackEvents } from "@/common/events/events";
import { getPlatformInfo } from "@/utils/GlobalJavascriptFunction";

const SellTradeHome = () => {
  const { t } = useTranslation(["web_home", "web_common"]);
  const lang = useLang();
  const {createUrl}= useHelpers();
  return (
    <>
      <div className={stl.container}>
        {lang === "en" ? (
          <ProgressiveImage
            waitUntilInteractive
            src={CDN_LINK + "/assets/images/trade_sell_en.svg"}
            width={179}
            height={80}
            alt="trade_sell"
          />
        ) : (
          <ProgressiveImage
            waitUntilInteractive
            src={CDN_LINK + "/assets/images/trade_sell.svg"}
            width={179}
            height={80}
            alt="trade_sell"
          />
        )}
        <div>
          <strong>
            {t("ودّك تبيع أو تبدل سيارتك بأفضل سعر؟", {
              ns: "web_home",
            })}
          </strong>
          <p>
            {t(
              "وفر وقتك وبيع سيارتك بسهولة، بدون أي تكاليف، حنا نتكفل بكافة الإجراءات وفوق هذا فحص معتمد وتصوير إحترافي مجاني",
              {
                ns: "web_home",
              }
            )}
          </p>
          <a
            className="blueBtn"
            href={createUrl(URL_SELL_TRADE_LANDING)}
            onClick={() => {
              trackEvents("sell_car_started", {
                referral_location: "homepage_card",
                platform: getPlatformInfo(),
              });
            }}
          >
            {t("قدّم طلبك الآن", {
              ns: "web_home",
            })}
          </a>
        </div>
      </div>
    </>
  );
};

export default SellTradeHome;
